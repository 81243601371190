<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-form-item label="维护人员"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-input v-decorator="['userName', { rules: [{ required: true, message: '请填写维护人员' }] }]" placeholder="请填写维护人员姓名"></a-input>
      </a-form-item>

      <a-form-item label="车辆" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-select v-decorator="['carId', { rules: [{ required: true, message: '请选择车辆' }] }]" @select="selectCar" placeholder="请选择车辆">
          <a-select-option
            v-for="item in carList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.platNumber }}({{item.status==='FREE'?'空闲中':item.status==='GOING'?'执行中':'离线'}})
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="部门" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select
          v-decorator="['orgId',{ rules: [{ required: true, message: '请选择部门' }] }]"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="orgTree"
          allowClear
          placeholder="请选择，默认当前登录用户所在部门"
        ></a-tree-select>
      </a-form-item>
      <a-form-item label="维护日期" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-date-picker
          style="width:100%"
          @change="onChange"
          v-decorator="['maintainTime', {initialValue: moment(), rules: [{ required: true, message: '维护日期不能为空' }]}]">
        </a-date-picker>
      </a-form-item>
      <a-form-item label="恢复日期" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-date-picker style="width:100%" @change="recoverTimeOnchange" :value="recoverTime"></a-date-picker>
      </a-form-item>
      <a-form-item label="维护内容" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-textarea placeholder="请填写维护内容" :rows="4" v-decorator="['memo',{rules: [{ required: true, message: '维护内容不能为空' }]}]"></a-textarea>
      </a-form-item>
      <a-form-item label="上传图片" :label-col="labelCol" :wrapper-col="wrapperCol">
        <qiniu-image-upload
          ref="qiniuImageRef"
          @getImageData="getImageData"
          :document-dir-id="40"
          :multiSelection="true"
          type="edit"
          :items="formItem.images"
        ></qiniu-image-upload>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import { carMaintainFormVO } from './common/carMaintainFormVO'
  import entityCURDCallback from '../../../common/mixins/entityCURDCallback'
  import SERVICE_URLS from '@/api/service.url'
  import moment from 'moment'
  import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'

  export default {
    name: 'CarMaintainForm',
    components: { QiniuImageUpload },
    mixins: [entityCURDCallback],
    props: {
      type: {
        type: String,
        default: 'add'
      }
    },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.csgz.carMaintainApi,
        initFormItem: carMaintainFormVO(),
        showFormItem: false,
        userTreeData: [],
        carList: [],
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
        orgTree: [],
        dateValue: '',
        recoverTime: undefined
      }
    },
    computed: {},
    mounted () {
      this.loadCarInfoList()
      this.getOrgTree()
    },
    methods: {
      moment,
      loadCarInfoList () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.carInfoApi.select,
          noTips: true,
          success: (data) => {
            this.carList = data.body
          }
        })
      },
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        values.images.forEach((item) => {
          this.formItem.imageIds.push(item.id)
        })
        this.dateValue = moment(this.formItem.maintainTime, 'YYYY-MM-DD')
        this.recoverTime = this.formItem.recoverTime ? moment(this.formItem.recoverTime).format('YYYY-MM-DD') : undefined
        this.form.setFieldsValue({
          carId: this.formItem.carId,
          memo: this.formItem.memo,
          userName: this.formItem.userName,
          orgId: this.formItem.orgId
        })
      },
      setVOFields (values) {
        Object.assign(this.formItem, values)
      },
      onChange (date, dateString) {
        this.dateValue = date
        this.formItem.maintainTime = dateString
      },
      recoverTimeOnchange (date, dateString) {
        this.recoverTime = date
        this.formItem.recoverTime = dateString
      },
      selectCar (value) {
        this.$http(this, {
          url: SERVICE_URLS.csgz.carInfoApi.view,
          noTips: true,
          params: { id: value },
          success: (data) => {
            this.form.setFieldsValue({
              orgId: data.body.carInfo.department.id
            })
          }
        })
      },
      /*上传图片回调方法*/
      getImageData (data) {
        this.formItem.imageIds = []
        data.forEach((item) => {
          this.formItem.imageIds.push(item.id)
        })
      }
    }
  }
</script>

<style scoped></style>
