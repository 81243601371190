export const carMaintainFormVO = function () {
  return {
    id: '',
    carId: '',
    maintainTime: '',
    memo: '',
    userName: '',
    orgId: '',
    recoverTime: '',
    imageIds: [],
    images: []
  }
}

export const carMaintainDetailVO = function () {
  return {
    maintainTime: '',
    memo: '',
    userName: '',
    orgId: '',
    platNumber: '',
    orgName: '',
    recoverTime: '',
    imageIds: [],
    images: [],
    reportUser: null
  }
}