<template>
  <a-skeleton active :loading="ifSub">
    <div class="details">
      <div class="details-title">
        <div class="details-title-text">基础信息</div>
        <i class="details-title-text-i"></i>
      </div>
      <a-row class="details-content-rowBasicInfo">
        <a-col :span="24"><span>&nbsp;&nbsp;车牌号：</span>{{ formItem.platNumber || '-' }}</a-col>
        <a-col :span="24"><span>维护人员：</span> {{ formItem.userName || '-' }}</a-col>
        <a-col :span="24"><span>所属部门：</span> {{ formItem.orgName || '-' }}</a-col>
        <a-col :span="24"><span>维护日期：</span> {{ formItem.maintainTime ? moment(formItem.maintainTime).format('YYYY-MM-DD') : '-' }}</a-col>
        <a-col :span="24"><span>恢复日期：</span> {{ formItem.recoverTime ? moment(formItem.recoverTime).format('YYYY-MM-DD') : '-' }}</a-col>
        <a-col :span="24"><span>维护内容：</span> {{ formItem.memo || '-' }}</a-col>
        <a-col :span="24"><span>填报人员：</span> {{ formItem.reportUser ? formItem.reportUser.name : '' || '-' }}</a-col>
      </a-row>
      <div class="details-title">
        <div class="details-title-text">图片信息</div>
        <i class="details-title-text-i"></i>
      </div>
      <div class="details-content-rests">
        <template>
          <qiniu-image-upload ref="qiniuImageRef" type="view" :items="formItem.images" style="margin-top: -15px"/>
        </template>
      </div>
    </div>
  </a-skeleton>
</template>

<script>
import { carMaintainDetailVO } from './common/carMaintainFormVO'
import SERVICE_URLS from '@/api/service.url'
import moment from 'moment'
import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'

export default {
  name: 'MaintainDetail',
  components: { QiniuImageUpload },
  data() {
    return {
      ifSub: false,
      formItem: carMaintainDetailVO(),
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    }
  },
  methods: {
    moment,
    loadData(id) {
      this.$http(this, {
        url: SERVICE_URLS.csgz.carMaintainApi.view,
        params: { id },
        noTips: true,
        success: (data) => {
          Object.assign(this.formItem, data.body)
        },
      })
    },
  },
}
</script>

<style scoped>
</style>
